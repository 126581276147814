import { useCallback, useEffect, useRef, useState } from "react"
import { CheckPicker } from "rsuite"
import localization from "localization"
import { useDynamicData } from "utils/useDynamicData"
import { useDebouncedCallback } from "use-debounce"
import { useFormContext } from "react-hook-form"
import FormHiddenInput from "./FormHiddenInput"
import { useUpdateEffect } from "usehooks-ts"

const FormStaticSearchCheckPicker = ({ item, name, onChange, formPath, conditionName }) => {
  const ref = useRef(null)
  const [url, setUrl] = useState("")
  const { data, isLoading } = useDynamicData(url, (item?.Data?.isAutocomplete || "") === "true")
  const [selectedValues, setSelectedValues] = useState([])
  const [pickerData, setPickerData] = useState(null)
  const debounceCallback = useDebouncedCallback((val) => {
    if (val?.length === 0) {
      setPickerData(
        data.data.result.map((item) => ({
          label: item.text,
          value: item.value,
        })),
      )
    }

    if ((val?.length || 0) < 2) return
    const currentSelectedValues = getValues(`${name}`) || []
    setPickerData([
      ...data.data.result
        .filter((item) => item.text.toLowerCase().includes(val.toLowerCase()) || currentSelectedValues.includes(item.value))
        .map((item) => ({
          label: item.text,
          value: item.value,
        })),
    ])
  }, 500)

  const { watch, setValue, getValues, resetField } = useFormContext()

  // Get parameters by selected condition.
  const parameters = watch(`${formPath}.conditionSelect`)

  const getInitialValues = useCallback(() => {
    let values = []
    if (item?.Data?.isAutocomplete === "true") {
      if (!getValues(`${name}`) || !getValues(`${name}_Text`)) return []

      const newPickerData = getValues(`${name}`).map((item, index) => {
        const textVal = getValues(`${name}_Text`)?.split("__")
        return {
          label: textVal[index] || item,
          value: item,
        }
      })
      values = newPickerData
    }

    return values
  }, [getValues, item?.Data?.isAutocomplete, name])

  useEffect(() => {
    let mappedData = []

    if (data?.data?.result?.length > 0) {
      mappedData.push(
        ...data?.data?.result?.map((item) => ({
          label: item.text || item.Text,
          value: item.value || item.Value,
        })),
      )
    }

    if (mappedData.length === 0 && data?.data?.length > 0) {
      mappedData.push(
        ...(data?.data || []).map((item) => ({
          label: item.text || item.Text,
          value: item.value || item.Value,
        })),
      )
    }

    if (mappedData.length === 0 && !!item?.Items) {
      const itemsArray = Object.entries(item?.Items)

      mappedData.push(
        ...(itemsArray.map((item) => ({
          label: item[1].Title,
          value: item[0],
        })) || []),
      )
    }
    const initialValues = getInitialValues()
    setPickerData([
      ...selectedValues,
      ...initialValues.filter((item) => !selectedValues.some((someItem) => someItem.value === item.value)),
      ...mappedData.filter(
        (item) =>
          !selectedValues.some((someItem) => someItem.value === item.value) &&
          !initialValues.some((someItem) => someItem.value === item.value),
      ),
    ])
  }, [data, getInitialValues, getValues, item?.Items, name, parameters, selectedValues, url])

  useEffect(() => {
    setUrl(item?.Data?.url || "")
  }, [item?.Data?.url])

  useUpdateEffect(() => {
    resetField(name, { defaultValue: [] })
  }, [conditionName])

  const renderConditions = () => {
    const values = getValues(`${name}`) || [];
    if (!pickerData || !Array.isArray(values)) return false
    const hasUnMatchedOrEmpty = values.some((value) => !pickerData.some((item) => item.value === value))
    if (values.length > 0 && hasUnMatchedOrEmpty) return false
    return true
  }

  return (
    <>
      {renderConditions() && (
        <div ref={ref}>
          <CheckPicker
            placeholder={item?.Title || "Seçim Yapınız"}
            name={name}
            container={ref.current}
            menuStyle={{ width: ref.current?.clientWidth + 20 }}
            block
            searchable={data?.data?.result?.length > 5}
            onSearch={debounceCallback}
            searchBy={(item) => true}
            cleanable={false}
            loading={isLoading}
            data={pickerData}
            value={getValues(`${name}`) || []}
            locale={localization.TR.PickerLocaleType.Picker}
            onChange={
              // @param val: Array<string | number>
              (val) => {
                const currenctValues = pickerData.filter((item) => val.includes(item.value))
                setSelectedValues(currenctValues)
                onChange(val)
                setValue(`${name}_Text`, currenctValues.map((item) => item.label).join("__"))
              }
            }
          />
          <FormHiddenInput
            type="hidden"
            name={`${name}_Text`}
          />
        </div>
      )}
    </>
  )
}

export default FormStaticSearchCheckPicker
