import React from "react"
import SocialMediaBox from "./components/SocialMediaBox"
import { getBasePath, isHostNameValid } from "utils/pathUtils"

const SocialMediaContent = ({ setSelectedContent, getSettingsData }) => {
  const hostNames = isHostNameValid(window.location.host)
  const basePath = getBasePath(hostNames)

  const activeItems = getSettingsData?.Result?.filter(
    (item) => item.PropertyName === "Active" && item.PropertyValue === "True",
  ).map((item) => item.SettingName)

  const customIconList = getSettingsData?.Result?.filter(
    (item) => item.PropertyName === "CustomIcon" && activeItems.includes(item.SettingName),
  ).reduce((acc, item) => {
    acc[item.SettingName] = item.PropertyValue
    return acc
  }, {})

  const socialMediaList = getSettingsData?.Result?.filter(
    (item) => item.PropertyName === "Address" && activeItems.includes(item.SettingName),
  ).map((item) => {
    const icon = customIconList[item.SettingName]
      ? !customIconList[item.SettingName].includes("https")
        ? `https://${basePath}${customIconList[item.SettingName]}`
        : customIconList[item.SettingName]
      : item.SettingName.toLowerCase()
    return {
      icon,
      title: item.SettingName,
      link: item.PropertyValue,
    }
  })

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-8 mt-6 flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <h3 className="text-gray-800 text-lg font-semibold">Sosyal Medya Bilgileri</h3>
        <button
          className="text-blue-500 font-medium"
          onClick={() => setSelectedContent("Sosyal Medya")}
        >
          {socialMediaList?.length > 0 ? "Düzenle" : "Sosyal Medya Bilgilerini Ekle"}
        </button>
      </div>
      {socialMediaList?.length > 0 ? (
        <div className="grid grid-cols-2 gap-4">
          {socialMediaList?.map((item, index) => (
            <SocialMediaBox
              key={index}
              icon={item.icon}
              title={item.title}
              link={item.link}
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-500 mt-2">Satış kanalınızın footer alanında görüntülenecek sosyal medya ikonlarını düzenleyin.</p>
      )}
    </div>
  )
}

export default SocialMediaContent
