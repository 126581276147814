import React, { useEffect, useState, useRef } from "react"
import { SelectPicker } from "rsuite"

// Components
import MainHeader from "components/Headers/MainHeader"
import PreviewAreaSettings from "components/Setup-Settings/PreviewAreaSettings"
import LogoUpload from "components/LogoUpload"
import { TitleH1, TitleH3, FontViewSettings, ColorViewSettings } from "components"
import ViewSettingsSkeleton from "skeletons/ViewSettings"
import { DefaultVisualStyles } from "../PageManager/Enums"

// Requests
import { useDispatch, useSelector } from "react-redux"
import { listConfigurationRequested } from "pages/B2cDemoPage/redux/actions/ListConfiguration"
import { getConfigurationRequested } from "pages/B2cDemoPage/redux/actions/GetConfiguration"
import { clientConfigRequested } from "redux/actions/clientConfiguration"
import { rgbaToHEX } from "helpers/rgbToHEX"

const ViewSettingsPage = (props) => {
  let { isLogged, AssociatedDomains } = props

  const textTypeWrapper = useRef()

  const dispatch = useDispatch()

  const DomainId = AssociatedDomains?.domainId
  const DomainType = AssociatedDomains?.domainType

  // selectors
  const ListConfiguration = useSelector((state) => state.ListConfiguration.result)
  const ListConfigurationLoading = useSelector((state) => state.ListConfiguration.loading)
  const GetConfiguration = useSelector((state) => state.GetConfiguration.result)
  const GetConfigurationLoading = useSelector((state) => state.GetConfiguration.loading)
  const DomainInfoState = useSelector((state) => state.associatedDomains.data)

  // states
  const [DefaultThemeButtonColor] = useState(DefaultVisualStyles.button.backgroundColor)
  const [DefaultThemeLinkColor] = useState(DefaultVisualStyles.button.backgroundColor)
  const [AllDisplays, setAllDisplays] = useState(null)
  const [AllFonts, setAllFonts] = useState(null)
  const [ActiveDisplays, setActiveDisplays] = useState(null)
  const [ActiveFontFamily, setActiveFontFamily] = useState(null)
  const [ActiveThemeButtonColor, setActiveThemeButtonColor] = useState(DefaultThemeButtonColor)
  const [ActiveThemeLinkColor, setActiveThemeLinkColor] = useState(DefaultThemeLinkColor)
  const [ActiveThemeLinkType, setActiveThemeLinkType] = useState(null)
  const [GetThemeConfig, setGetThemeConfig] = useState(null)
  const [ThemeConfig, setThemeConfig] = useState({
    displaySettings: {
      borderRadius: ActiveDisplays?.styles?.borderRadius,
      fontFamily: ActiveDisplays?.styles?.fontFamily,
    },
  })
  const [editViewSettings, setEditViewSettings] = useState(false)

  // Func
  const setConfig = (config) => {
    if (config) {
      setThemeConfig({
        ...ThemeConfig,
        displaySettings: {
          ...ThemeConfig.displaySettings,
          ...config,
        },
      })
    }
  }

  const getPageRequestsSync = () => {
    dispatch(
      listConfigurationRequested({
        query: `domainType=${DomainType}`,
      }),
    )
    dispatch(
      getConfigurationRequested({
        domainType: DomainId,
      }),
    )
  }

  const saveThemeConfig = async () => {
    let buttonColor
    if (ThemeConfig?.displaySettings?.buttonColor.startsWith("#")) {
      buttonColor = ThemeConfig?.displaySettings?.buttonColor
    } else {
      buttonColor = rgbaToHEX(ThemeConfig?.displaySettings?.buttonColor)
    }

    let obj = {
      ThemeConfiguration: ThemeConfig.displaySettings?.activeDisplay
        ? ThemeConfig.displaySettings?.activeDisplay
        : ActiveDisplays.label,
      ColorConfiguration: JSON.stringify({ color: buttonColor }),
      FontConfiguration: ActiveFontFamily,
    }
    setEditViewSettings(false)

    var formData = new FormData()
    for (let key in obj) {
      formData.append(key, obj[key])
    }

    const lpLogoFile = ThemeConfig.displaySettings?.logoValues?.fileForBinary
    if (!lpLogoFile) {
      await fetch(ThemeConfig.displaySettings?.logoValues.src)
        .then((res) => res.blob())
        .then((file) => {
          formData.append("ClientLogo", file, file.name)
        })
    } else {
      formData.append("ClientLogo", lpLogoFile, lpLogoFile.name)
    }

    dispatch(clientConfigRequested(formData))
  }

  useEffect(() => {
    setThemeConfig({
      ...ThemeConfig,
      displaySettings: {
        ...ThemeConfig.displaySettings,
        borderRadius: ActiveDisplays?.styles?.borderRadius,
        fontFamily: ActiveFontFamily,
        buttonColor: ActiveThemeButtonColor,
        linkColor: ActiveThemeLinkColor,
        linkType: ActiveThemeLinkType,
      },
    })
    // eslint-disable-next-line
  }, [ActiveDisplays, ActiveThemeButtonColor, ActiveThemeLinkColor, ActiveThemeLinkType, GetThemeConfig, ActiveFontFamily])

  // request
  useEffect(() => {
    if (!ListConfigurationLoading && !GetConfigurationLoading) {
      if (DomainId && DomainType) {
        getPageRequestsSync()
      } else {
        if (DomainInfoState && DomainInfoState.length) {
          getPageRequestsSync()
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, DomainInfoState, DomainId, DomainType])

  // data manipulation
  useEffect(() => {
    if (ListConfiguration !== null) {
      // add background-color to response list
      let displays = ListConfiguration?.displays.reduce((acc, value) => {
        acc.push({
          ...value,
          backgroundColor: "#EFF8FF",
        })
        return acc
      }, [])

      // compare if GetThemeConfig is not empty
      let activeDisplay = displays ? displays[0] : {}
      let activeFontFamily = ListConfiguration?.fonts[0]?.value
      let activeThemeLinkType = ListConfiguration?.links[0]?.value
      let activeThemeButtonColor = DefaultThemeButtonColor
      let activeThemeLinkColor = DefaultThemeLinkColor
      if (GetThemeConfig?.configurations.length) {
        activeDisplay = [
          GetThemeConfig?.configurations.find(({ configurationMetadataCode }) => configurationMetadataCode === "theme"),
        ].reduce((acc, value) => {
          acc = {
            label: value.name,
            styles: value.properties,
          }
          return acc
        }, [])

        activeFontFamily = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "font",
        )?.name
        activeThemeLinkType = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "linktype",
        )?.properties?.textDecorationLine
        activeThemeButtonColor = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "color",
        )?.properties?.color
        activeThemeLinkColor = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "linkcolor",
        )?.properties?.linkcolor

        /*if(!!activeDisplay && !!activeFontFamily && !!activeThemeLinkType && !!activeThemeButtonColor){}*/
        setThemeConfig({
          ...ThemeConfig,
          displaySettings: {
            ...ThemeConfig.displaySettings,
            logoValues: {
              src: GetThemeConfig?.logoUrl,
              name: GetThemeConfig?.logoUrl,
            },
          },
        })
        /*
        setActiveFontFamily(ListConfiguration.fonts[0].value)
        setActiveThemeLinkType(ListConfiguration.links[0].value)*/
      }
      setAllDisplays(displays)
      setActiveDisplays(activeDisplay)
      setAllFonts(ListConfiguration?.fonts)
      setActiveFontFamily(activeFontFamily)
      setActiveThemeLinkType(activeThemeLinkType)
      setActiveThemeButtonColor(activeThemeButtonColor)
      setActiveThemeLinkColor(activeThemeLinkColor)
    }
    // eslint-disable-next-line
  }, [GetThemeConfig, ListConfiguration])

  // getConfiguration
  useEffect(() => {
    if (GetConfiguration) {
      setGetThemeConfig(GetConfiguration)
    }
  }, [GetConfiguration])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      {GetConfigurationLoading && <ViewSettingsSkeleton />}
      {!GetConfigurationLoading && (
        <main className="bg-gray-25 z-10">
          <section className="p-10">
            <div className="container">
              <div className="flex flex-row justify-between mb-6">
                <div className=" justify-start">
                  <TitleH1>Görünüm Ayarları</TitleH1>
                  <p className="text-base text-gray-500 mt-2">
                    Rezervasyon sayfanızı dilediğiniz gibi kişiselleştirip düzenleyebilirsin.
                  </p>
                </div>
                <div className="justify-end">
                  {!editViewSettings ? (
                    <button
                      onClick={() => setEditViewSettings(true)}
                      className="btn lg"
                    >
                      Düzenle
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => setEditViewSettings(false)}
                        className="btn outline lg mr-2"
                      >
                        Vazgeç
                      </button>
                      <button
                        onClick={saveThemeConfig}
                        className="btn lg"
                      >
                        Kaydet
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="border relative border-gray-200 mt-6 p-9 flex items-center justify-between flex-col lg:flex-row bg-white rounded-lg">
                <div className="mb-10 lg:mb-0 w-auto lg:w-1/2 lg:mr-14">
                  {editViewSettings ? (
                    <>
                      <LogoUpload
                        hasHotelWidget={true}
                        setConfig={(config) => setConfig(config)}
                        config={{
                          ThemeConfig,
                        }}
                      />
                      <p className="text-sm text-gray-800 font-medium mb-2">Tema</p>
                      <div className="mb-8">
                        <FontViewSettings
                          setConfig={(config) => setConfig(config)}
                          row={false}
                          config={{
                            ThemeConfig,
                            AllDisplays,
                          }}
                        />
                      </div>
                      <div
                        ref={textTypeWrapper}
                        className="ds-box relative mb-8"
                      >
                        <TitleH3 className="mb-2">Yazı Tipi</TitleH3>

                        <div className="box-content relative">
                          <SelectPicker
                            block
                            placeholder={""}
                            value={ActiveFontFamily}
                            menuMaxHeight={164}
                            cleanable={false}
                            data={AllFonts ? AllFonts : []}
                            searchable={false}
                            container={textTypeWrapper.current}
                            onSelect={(value, item) => {
                              setActiveFontFamily(value)
                            }}
                          />
                        </div>
                      </div>
                      <ColorViewSettings
                        showTootip={false}
                        setConfig={(config) => setConfig(config)}
                        config={{
                          ThemeConfig,
                          DefaultThemeButtonColor,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p className="text-sm text-gray-800 font-medium mb-2">Marka Logosu</p>
                      <div className="bg-white flex items-center justify-center border border-dashed border-gray-200 p-4 rounded-lg shadow-sm mb-8 h-32">
                        {ThemeConfig.displaySettings?.logoValues?.src && (
                          <img
                            src={ThemeConfig.displaySettings?.logoValues?.src}
                            alt="Logo"
                            className={`max-h-24`}
                          />
                        )}
                      </div>
                      <p className="text-sm text-gray-800 font-medium mb-2">Tema</p>
                      <div className="bg-white border border-solid border-gray-200 p-4 rounded-lg shadow-sm mb-8">
                        <p className="text-sm text-gray-800 font-medium">{ActiveDisplays?.label}</p>
                      </div>
                      <p className="text-sm text-gray-800 font-medium mb-2">Yazı Tipi</p>
                      <div className="bg-white border border-solid border-gray-200 p-4 rounded-lg shadow-sm mb-8">
                        <p className="text-sm text-gray-800 font-medium">{ActiveFontFamily}</p>
                      </div>
                      <p className="text-sm text-gray-800 font-medium mb-2">Renk Seçimi</p>
                      <span
                        className={`h-9 w-9 inline-flex rounded-lg`}
                        style={{
                          backgroundColor: ThemeConfig.displaySettings?.buttonColor,
                          outlineColor: ThemeConfig.displaySettings?.buttonColor,
                        }}
                      >
                        &nbsp;
                      </span>
                    </>
                  )}
                </div>
                <PreviewAreaSettings
                  type={DomainType}
                  config={{ ThemeConfig }}
                />
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  )
}

export default ViewSettingsPage
