import { Icon } from "components"
import { Col, Grid, Row, SelectPicker, Dropdown, Placeholder } from "rsuite"
import React, { useEffect, useState } from "react"
import DataGrid, {
  Column,
  FilterRow,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Sorting,
  Toolbar,
  Item,
} from "devextreme-react/data-grid"
import { useNavigate } from "react-router"
import { useRuleList } from "utils/useRules"
import { useRuleTypeList } from "utils/useRules"
import NoDataScreen from "./NoDataScreen"

const RuleType = ({ RuleTypeId }) => {
  const { data, isLoading } = useRuleTypeList(RuleTypeId)
  return (
    <>
      {isLoading && (
        <Placeholder.Graph
          className="w-full max-h-[45px] my-1"
          active
        />
      )}
      {!!data && !isLoading && <div className="px-2">{data.name}</div>}
    </>
  )
}

const initialFilter = {
  Page: 1,
  PageSize: 1000,
  SortColumn: "Id",
  Name: undefined,
  Type: undefined,
  Status: undefined,
}

const RuleDataList = () => {
  const [TableDraggableLines] = useState(false)
  const [tableFilter, setTableFilter] = useState(initialFilter)
  const [isFiltered, setIsFiltered] = useState(false)
  const { data, isLoading } = useRuleList(tableFilter)
  const navigate = useNavigate()
  const { data: ruleTypeList } = useRuleTypeList()

  const tableStates = {
    cellAlignments: "center",
    rowWidth: "20%",
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      keyExpr: "id",
      noDataText: "Veri Yok",
    },
    paging: {
      defaultPageSize: 10,
    },
    pager: {
      visible: true,
      allowedPageSizes: [10, 20, 30],
      displayMode: "full",
      showPageSizeSelector: true,
      showInfo: true,
      showNavigationButtons: true,
      // infoText: `${data?.data?.result?.recordsTotal} sonuçtan ${data?.data?.result?.data?.length || 0} listeleniyor`,
    },
    selection: {
      mode: "multiple",
      selectAllMode: "allMode",
      showCheckBoxedMode: "always",
    },
  }

  useEffect(() => {
    // This removes if there is any form values in the sessionStorage
    sessionStorage.removeItem("formValues");
    
    if (tableFilter.Name || tableFilter.Type || tableFilter.Status !== undefined) {
      setIsFiltered(true)
    } else {
      setIsFiltered(false)
    }
  }, [tableFilter])

  return (
    <>
      {isLoading && (
        <div className="flex flex-col gap-10 items-start justify-start  w-full h-full">
          <Placeholder.Paragraph
            className="w-full"
            active
          />
          <Placeholder.Graph
            active
            className="w-full h-full"
          />
        </div>
      )}

      {!isLoading && ((data?.data?.result?.data || []).length !== 0 || isFiltered) && (
        <Grid fluid>
          {/* Title & Add New Rule Button */}
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
            >
              <p className="text-gray-800 font-semibold text-3xl">Kurallar</p>
              <p className="text-gray-500 text-sm font-thin">Kurallarınızı görüntüleyin ve yeni kurallar ekleyin.</p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="flex justify-end"
            >
              <Dropdown
                renderToggle={(props, ref) => (
                  <button
                    {...props}
                    ref={ref}
                    className="btn text-sm"
                  >
                    <Icon
                      name="plus"
                      size={18}
                      className="!bg-white"
                    />
                    Kural Ekle
                  </button>
                )}
              >
                <Dropdown.Item onClick={() => navigate("/rules/addNewRule/searchRule")}>Arama Kuralı</Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/rules/addNewRule/incomeRule")}>Bütçe Kuralı</Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/rules/addNewRule/confirmationRule")}>Onay Kuralı</Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/rules/addNewRule/reservationRule")}>Rezervasyon Kuralı</Dropdown.Item>
              </Dropdown>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={24}>
              <DataGrid
                className={`${TableDraggableLines ? "draggable-active" : ""} filters-active rule-grid`}
                {...tableStates.dataGrid}
                dataSource={data?.data?.result?.data}
                style={{ borderRadius: "!10px" }}
              >
                {/*TABLE OPTIONS*/}
                {!TableDraggableLines && false && <Selection {...tableStates.selection} />}
                {!TableDraggableLines && (
                  <SearchPanel
                    placeholder={"Kural adı veya oluşturan kişi arayın"}
                    visible={true}
                    width="288"
                  />
                )}
                {/*TABLE OPTIONS*/}
                <Selection
                  {...tableStates.selection}
                  showCheckBoxesMode={"always"}
                />
                {!TableDraggableLines && <Sorting mode="multiple" />}
                {!TableDraggableLines && <FilterRow {...tableStates.filterRow} />}
                {!TableDraggableLines && <Paging {...tableStates.paging} />}
                {!TableDraggableLines && <Pager {...tableStates.pager} />}
                {/*TABLE OPTIONS*/}
                <Toolbar>
                  <Item
                    location="before"
                    name="searchPanel"
                  />
                  <Item location="after">
                    <div className="flex justify-end gap-2 w-full">
                      {/* @todo - bu alan dinamik olacak */}
                      <SelectPicker
                        data={
                          ruleTypeList?.map((item) => ({
                            key: item.id,
                            label: item.name,
                            value: item.id.toString(),
                          })) || []
                        }
                        style={{ width: 224 }}
                        searchable={false}
                        cleanable={true}
                        placeholder="Kural Tipi"
                        value={tableFilter?.Type}
                        onChange={(value) => {
                          setTableFilter({
                            ...(tableFilter || initialFilter),
                            Type: value,
                          })
                        }}
                      />
                      <SelectPicker
                        data={[
                          {
                            label: "Aktif",
                            value: "active",
                          },
                          {
                            label: "Pasif",
                            value: "passive",
                          },
                        ]}
                        style={{ width: 224 }}
                        searchable={false}
                        cleanable={true}
                        placeholder="Statü"
                        value={tableFilter?.Status ? "active" : tableFilter?.Status === 0 ? "passive" : undefined}
                        onChange={(value) => {
                          setTableFilter({
                            ...(tableFilter || initialFilter),
                            Status: value === "active" ? 1 : value === "passive" ? 0 : undefined,
                          })
                        }}
                      />
                    </div>
                  </Item>
                </Toolbar>

                {/*TABLE BODY*/}
                <Column
                  caption="Kural Adı"
                  dataField="name"
                  allowFiltering={true}
                  allowSearch={true}
                  allowHeaderFiltering={false}
                  width={tableStates.rowWidth}
                  alignment={"left"}
                  cellRender={(row) => (row.text ? <div className="px-2">{row.text}</div> : "-")}
                />
                <Column
                  caption="Kural Tipi"
                  dataField="type"
                  allowFiltering={false}
                  allowHeaderFiltering={true}
                  width={tableStates.rowWidth}
                  alignment={"left"}
                  cellRender={(row) => (row.text ? <RuleType RuleTypeId={row.text} /> : "-")}
                />
                <Column
                  caption="Statü"
                  dataField="status"
                  cellRender={(row) => {
                    return row?.data?.status ? (
                      <div className="h-[56px] flex items-center justify-center">
                        <div className="p-1 h-[22px] max-h-[45px] px-2 rounded-xl bg-green-100 text-green-500 leading-[14px] ">
                          Aktif
                        </div>
                      </div>
                    ) : (
                      <div className="h-[56px] flex items-center justify-center">
                        <div className="p-1 h-[22px] max-h-[45px] px-2 rounded-xl bg-red-100 text-red-500 leading-[14px]">
                          Pasif
                        </div>
                      </div>
                    )
                  }}
                  allowFiltering={true}
                  allowHeaderFiltering={false}
                  width={"10%"}
                  alignment={tableStates.cellAlignments}
                />
                <Column
                  caption="Öncelik"
                  dataField="priority"
                  allowFiltering={true}
                  allowHeaderFiltering={false}
                  width={"10%"}
                  alignment={tableStates.cellAlignments}
                />
                <Column
                  caption="Oluşturulma Tarihi"
                  dataField="createDate"
                  dataType="date"
                  cellRender={(row) => (row.text ? <div className="px-3">{row.text}</div> : "-")}
                  allowFiltering={false}
                  allowHeaderFiltering={true}
                  width={tableStates.rowWidth}
                  alignment={"left"}
                />
                <Column
                  caption="Düzenleme Tarihi"
                  dataField="updateDate"
                  dataType="date"
                  cellRender={(row) => (row.text ? <div className="px-3">{row.text}</div> : "-")}
                  allowFiltering={true}
                  allowHeaderFiltering={false}
                  width={tableStates.rowWidth}
                  alignment={"left"}
                />
                <Column
                  caption="Oluşturan Kullanıcı"
                  dataField="createdByUserName"
                  allowFiltering={true}
                  allowSearch={true}
                  allowHeaderFiltering={false}
                  width={tableStates.rowWidth}
                  cellRender={(row) => (row.text ? <div className="px-3">{row.text}</div> : "-")}
                  alignment={"left"}
                />
                <Column
                  caption="İşlemler"
                  width={"15%"}
                  fixed={true}
                  fixedPosition={"right"}
                  cellRender={(row) => {
                    const ButtonContent = (props, ref) => (
                      <Icon
                        ref={ref}
                        size={24}
                        name="more-vertical"
                        className={"!bg-blue-500"}
                        {...props}
                      />
                    )

                    const DropItem = (props) => (
                      <Dropdown.Item
                        icon={
                          <Icon
                            name={props.iconName ? props.iconName : ""}
                            size={14}
                            style={{ backgroundColor: props.color ? props.color : "#000000" }}
                          />
                        }
                        onClick={props.onClick}
                        className="flex items-center justify-start gap-3 px-4"
                        style={{ color: props.color ? props.color : "#000000" }}
                      >
                        {props.text}
                      </Dropdown.Item>
                    )

                    return (
                      <Dropdown
                        renderToggle={ButtonContent}
                        trigger={"click"}
                        placement="bottomStart"
                        className="flex items-center justify-center h-[50px]"
                      >
                        <DropItem
                          text="İncele"
                          iconName="eye"
                          onClick={() => navigate(`/rules/${row.data.id}`)}
                        />
                      </Dropdown>
                    )
                  }}
                  alignment={tableStates.cellAlignments}
                />
                {/*TABLE BODY*/}
              </DataGrid>
            </Col>
          </Row>
        </Grid>
      )}

      {!isLoading && (data?.data?.result?.data || []).length === 0 && !isFiltered && <NoDataScreen />}

      {/* STYLES */}
      <style jsx>{`
        #gridContainer .dx-row.dx-data-row.dx-column-lines {
          height: 56px;
        }
        .dx-widget .dx-datagrid .dx-row.dx-data-row.dx-column-lines > td {
          line-height: 56px;
          padding: 0px;
        }
        .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell:not([aria-label="Filter cell"]):before {
          background: none !important;
        }
        .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell:not([aria-label="Filter cell"]) {
          border-right: none !important;
        }
      `}</style>
    </>
  )
}

export default RuleDataList
