import { useQuery, useMutation } from "@tanstack/react-query"
import { API_POST, API_DELETE } from "helpers/api"

export const useMediaLibraryList = (Ids) => {
  return useQuery({
    queryKey: ["mediaLibraryList", Ids],
    queryFn: () => API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/medialibrary/list`, { Ids }),
  })
}

export const useSaveMediaLibraryItem = () => {
  return useMutation({
    mutationFn: (payload) => API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/medialibrary/save`, payload),
  })
}

export const useDeleteMediaLibraryItem = () => {
  return useMutation({
    mutationFn: (id) => API_DELETE(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/medialibrary/delete/${id}`),
  })
}
