/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react"
import { useFormContext, Controller } from "react-hook-form"
import { Toggle } from "rsuite"
import LogoUpload from "components/LogoUpload"
import { Alert, Icon } from "components"
import { useSaveMediaLibraryItem } from "services/wl-display/mediaLibrary/mediaLibrary"
import { getBasePath, isHostNameValid } from "utils/pathUtils"

const SocialMediaFormItem = ({ title, name, active, link, logo }) => {
  const { control, setValue } = useFormContext()
  const [showAlert, setShowAlert] = useState(false)
  const [ThemeConfig, setThemeConfig] = useState({})
  const [currentLogo, setCurrentLogo] = useState(logo)
  const [changes, setChanges] = useState({
    isActiveChanged: false,
    isLinkChanged: false,
    isLogoChanged: false,
  })

  const hostNames = isHostNameValid(window.location.host)
  const basePath = getBasePath(hostNames)

  const saveMediaMutation = useSaveMediaLibraryItem()

  const setConfig = useCallback(
    (config) => {
      if (config) {
        setThemeConfig((prev) => ({
          ...prev,
          displaySettings: {
            ...prev.displaySettings,
            ...config,
          },
        }))
        const newLogoPath = config?.logoValues?.fileForBinary?.name
        setValue(`${name}.logo`, newLogoPath)
        setChanges((prev) => ({ ...prev, isLogoChanged: true }))
        changes.isLogoChanged && setCurrentLogo(`https://${basePath}${newLogoPath}`)
      }
    },
    [setValue, name, basePath],
  )

  const handleRemoveLogo = () => {
    setCurrentLogo("")
    setValue(`${name}.logo`, "")
    setChanges((prev) => ({ ...prev, isLogoChanged: true }))
  }

  useEffect(() => {
    setCurrentLogo(logo)
    if (!changes.isActiveChanged) {
      setValue(`${name}.active`, active === "True")
    }
    if (!changes.isLinkChanged) {
      setValue(`${name}.link`, link)
    }
    if (!changes.isLogoChanged) {
      setValue(`${name}.logo`, logo)
    }
  }, [])

  return (
    <div className="flex flex-col gap-4 border border-gray-200 rounded-lg p-4 h-[209px]">
      <div className="flex justify-between items-center">
        <span className="text-lg text-gray-800 font-medium">{title}</span>
        <Controller
          name={`${name}.active`}
          control={control}
          defaultValue={active === "True"}
          render={({ field }) => (
            <Toggle
              {...field}
              checked={field.value}
              onChange={(value) => {
                field.onChange(value)
                setChanges((prev) => ({ ...prev, isActiveChanged: true }))
              }}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-gray-800 font-medium">Bağlantı linki</span>
        <Controller
          name={`${name}.link`}
          control={control}
          defaultValue={link}
          render={({ field }) => (
            <input
              type="text"
              defaultValue={link}
              onChange={(e) => {
                field.onChange(e.target.value)
                setChanges((prev) => ({ ...prev, isLinkChanged: true }))
              }}
              placeholder="Bağlantı linkini ekleyin"
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-[6px] h-12 max-h-12">
          {currentLogo && !currentLogo.includes("undefined") ? (
            <div className="flex justify-between items-center w-full border rounded-lg border-gray-200 p-3 h-12 max-h-12">
              <div className="flex items-center gap-1">
                <img
                  src={!currentLogo.includes("https") ? `https://${basePath}${currentLogo}` : currentLogo}
                  alt={title}
                  width={16}
                  height={16}
                />
                <span className="font-medium text-sm">{name}.png</span>
              </div>
              <span
                onClick={() => {
                  handleRemoveLogo()
                }}
                className="text-[#F04438] font-medium text-sm cursor-pointer"
              >
                Kaldır
              </span>
            </div>
          ) : (
            <>
              <Icon
                size={"16"}
                name="upload-cloud"
                color={"#2E90FA"}
              />
              <span
                onClick={() => setShowAlert(!showAlert)}
                className="text-[#2E90FA] font-medium cursor-pointer"
              >
                Özel ikon yükle
              </span>
            </>
          )}
        </div>
        {showAlert && (
          <Alert
            title="Sosyal Medya İkonu Yükle"
            rejectButtonText="Vazgeç"
            approveButtonText="Kaydet"
            rejectButtonOnClick={() => setShowAlert(!showAlert)}
            approveButtonOnClick={() => {
              const formData = new FormData()
              const logoFile = ThemeConfig?.displaySettings?.logoValues?.fileForBinary
              formData.append("File", logoFile)
              saveMediaMutation.mutate(formData, {
                onSuccess: (data) => {
                  const newLogoPath = data?.data?.result?.url
                  setValue(`${name}.logo`, newLogoPath)
                  setChanges((prev) => ({ ...prev, isLogoChanged: true }))
                  setCurrentLogo(newLogoPath)
                },
              })
              setShowAlert(!showAlert)
            }}
            iconName=""
            className="w-[450px]"
          >
            <Controller
              name={`${name}.logo`}
              control={control}
              defaultValue={logo}
              render={({ field }) => (
                <LogoUpload
                  accept={".jpg, .png"}
                  title={false}
                  showText="false"
                  favicon={false}
                  config={{ ThemeConfig }}
                  hasTitle={false}
                  setConfig={(config) => {
                    setConfig(config)
                    const newLogoPath = config?.logoValues?.fileForBinary?.name
                    field.onChange(newLogoPath)
                  }}
                />
              )}
            />
          </Alert>
        )}
      </div>
    </div>
  )
}

export default SocialMediaFormItem
