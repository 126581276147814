/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

// layouts
import BlockWrapper from "../../BlockWrapper"
import { TitleLayout } from "../../Design"
import Settings from "../../Design/Settings"

// components
import { TabList, SideMenuListItem } from "components"
import {TextHtml, TextHtmlDummy} from "./Editor";

// utils
import getSelectedIndex from "utils/getSelectedIndex"

//helpers
import {
  newTextHtml,
  TabListTabLabels,
} from "../../../Enums";
import {UpdateStrategy} from "../../../helpers/UpdateStrategy";
import {getStrategySucceed} from "../../../../../redux/actions/strategy";
import {setSettings} from "../../../../../helpers/setSettings";

// saga-requests

const AtomHtml = (props) => {
  let { show, activeLayout, setEditLayouts } = props

  const dispatch = useDispatch()

  let infoCodeForStrategy = activeLayout?.info?.code
  let code = infoCodeForStrategy || activeLayout?.code

  // selectors
  const components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [EditBlock, setEditBlock] = useState(null)
  const [ComponentDisplayTab, setComponentDisplayTab] = useState(0)
  const [ThisComponent, setThisComponent] = useState((components && components?.children && components?.children.find(child => child?.code === infoCodeForStrategy)) || newTextHtml(code, activeLayout?.order))
  const [UploadedImageInfo, setUploadedImageInfo] = useState(null)
  const [DefaultImageInfo, setDefaultImageInfo] = useState(ThisComponent?.data?.backgroundImagePath)
  const [ShowEditor, setShowEditor] = useState(false)
  const [EditorData, setEditorData] = useState()

  // Func
  const editComponentDisplayTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setComponentDisplayTab(selectedIndex)
  }

  const saveChangesOrGoBack = async () => {
    setEditLayouts(null)
  }

  useEffect(() => {
    if (activeLayout?.order !== undefined && typeof activeLayout?.order === "number"){
      setThisComponent({
        ...ThisComponent,
        order: activeLayout?.order
      })
    }
  }, [activeLayout])


  useEffect(() => {
    if (ThisComponent?.data){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }

  }, [ThisComponent])

  return (
    <BlockWrapper
      show={show}
      activeLayout={activeLayout}
      setEditLayouts={setEditLayouts}
      editBlock={EditBlock}
      setEditBlock={setEditBlock}
      ThisComponent={ThisComponent}
      setThisComponent={setThisComponent}
      doneBtnOnClick={saveChangesOrGoBack}
    >
      <>
        <TabList
          onChange={editComponentDisplayTabChanged}
          tabs={[
            { label: TabListTabLabels.content, icon: "list", disabled: false },
            { label: TabListTabLabels.design, icon: "palette-solid", disabled: false },
            { label: TabListTabLabels.publish, icon: "share", disabled: true },
          ]}
          tabClassName="w-full flex items-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
          wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mb-7"
          //itemActiveClass="!text-gray-800 !bg-blue-50"
          activeTab={ComponentDisplayTab}
        />

        {ComponentDisplayTab === 0 && (
          <>
            <div className="input-wrapper mb-4">
              <SideMenuListItem
                title={"Başlık"}
                iconName={"chevron-right"}
                onClick={() =>
                  setEditBlock({
                    showTitleLayout: true,
                  })
                }
              />
            </div>

            <button
              onClick={(e) => {
                setShowEditor(true)
              }}
              className="btn secondary xs w-full py-2 !h-auto border-b hover:bg-gray-100 last:border-0"
            >
              Metin Düzenleyiciyi Aç
            </button>
            {ShowEditor && (
              <div
                id="modalwrapper"
                className="flex flex-col justify-center max-w-[100vw] w-[calc(100vw-66px)] z-100 fixed left-0 top-0 h-full bg-[#34405480] px-[15vw] overflow-auto"
                onClick={(e) => {
                  if (e.target?.id === "modalwrapper") {
                    setShowEditor(false)
                  }
                  // setShowEditor(false)
                }}
              >
                <div className="bg-white max-h-[87%] flex flex-col gap-6 items-center justify-center p-4 rounded-xl">
                  <TextHtml
                      className={"shrink-1"}
                      content={ThisComponent?.data?.html || TextHtmlDummy}
                      getContent={(content) => {
                        setEditorData(content)
                      }
                  }/>
                  <div className="sticky bottom-0 bg-white btn-area flex justify-center w-full gap-4">
                    <button
                      //disabled={saveDesignFieldsValueLoading}
                      onClick={() => setShowEditor(false)}
                      className="btn secondary px-20"
                    >
                      Kapat
                    </button>
                    <button
                      //disabled={saveDesignFieldsValueLoading}
                      onClick={() => setSettings(
                        {target: {name:"html", value: EditorData}},
                        true,
                        ThisComponent,
                        "data").then(response => {
                        setThisComponent(response)
                        setShowEditor(false)
                      })}
                      className="btn px-20"
                    >
                      Kaydet
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {ComponentDisplayTab === 1 && (
          <Settings
            editBlock={EditBlock}
            setEditBlock={setEditBlock}
            setEditLayouts={setEditLayouts}
            UploadedImageInfo={UploadedImageInfo}
            setUploadedImageInfo={setUploadedImageInfo}
            ThisComponent={ThisComponent}
            setThisComponent={setThisComponent}
            DefaultImageInfo={DefaultImageInfo}
            setDefaultImageInfo={setDefaultImageInfo}
          />
        )}
      </>

      {/* region sub menus*/}
      <TitleLayout
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
    </BlockWrapper>
  )
}

export default AtomHtml
