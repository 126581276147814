import React from "react"

const Pagination = ({ currentPage, recordsTotal, handlePageSize, handlePreviewChange, handleMoreChange }) => {
  return (
    <div className="grid grid-cols-3 py-4 px-6">
      {/* <div>
        <button onClick={handlePageSize(10)}>10</button>
        <button onClick={handlePageSize(20)}>20</button>
        <button onClick={handlePageSize(30)}>30</button>
      </div> */}
      <div className="flex justify-start items-center"></div>
      <div className="flex justify-center items-center text-gray-700 text-sm font-medium">
        {/* {`${recordsTotal} sonuçtan ${currentPage} / ${currentPage * pageSize}  arası`} */}
        {`Sayfa ${currentPage} (${recordsTotal} veri)`}
      </div>
      <div className="flex justify-end items-center gap-3">
        <button
          disabled={currentPage * 10 <= 10}
          className={`bg-white rounded-lg py-2 px-[14px] text-sm border border-gray-300 text-gray-500 ${currentPage * 10 <= 10 ? "opacity-50" : ""}`}
          onClick={handlePreviewChange}
        >
          Önceki sayfa
        </button>
        <button
          disabled={currentPage * 10 >= recordsTotal}
          className={`bg-white rounded-lg py-2 px-[14px] text-sm border border-gray-300 text-gray-500 ${currentPage * 10 >= recordsTotal ? "opacity-50" : ""}`}
          onClick={handleMoreChange}
        >
          Sonraki sayfa
        </button>
      </div>
    </div>
  )
}

export default Pagination
