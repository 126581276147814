import React from "react"
import { Grid, Row, Col, SelectPicker, Button, Tooltip, Whisper } from "rsuite"
import { Card, Icon } from "components"
import NewAreaButton from "../NewAreaButton"
import localization from "localization"
import { Controller, useFormContext, useFieldArray } from "react-hook-form"
import RulesCardInputs from "./RulesCardInputs"

const fieldArrayName = "rule.children"

const RuleFormItemsCard = () => {
  const { control, getValues, setValue, reset } = useFormContext()
  const { fields, append } = useFieldArray({
    control,
    name: fieldArrayName,
    defaultValues: {
      [fieldArrayName]: [],
    },
  })

  const handleNewGroup = () => {
    append({
      multipleCondition: "and",
      relatedCondition: "and",
      children: [
        {
          conditionSelect: undefined,
        },
      ],
    })
  }

  const handleRemoveGroup = (index) => {
    let fieldValues = getValues(fieldArrayName)

    if (index === 0) {
      // If the first item is removed, the related condition of the second item should be removed.
      // So, for BE want to 'and' value as default at the first item, we assigned 'and' value to the second item.
      fieldValues[1].relatedCondition = "and"
    }
    // Remove the item from the array
    fieldValues.splice(index, 1)
    setValue(fieldArrayName, fieldValues)
    const formValues = getValues()

    // Save form values to local storage as default values while editing.
    const editId = getValues("rule.Id")
    // These id values used for checking the form values in the local storage while initializing the form.
    sessionStorage.setItem("formValues", JSON.stringify({ id: editId || "newRule", data: formValues }))
    
    reset(formValues)
  }

  return (
    <Row>
      {fields.map((item, index) => (
        <RulesInputGroup
          key={item.id}
          index={index}
          name={`rule.children[${item.id}].children`}
          title={`${index + 1}. Koşul`}
          checkboxVisible={true}
          onAddNewGroup={handleNewGroup}
          footerVisible={index === fields.length - 1}
          titleVisible={index === 0}
          onRemoveThisGroup={fields.length > 1 ? () => handleRemoveGroup(index) : undefined}
        />
      ))}
    </Row>
  )
}

/**
 * @typedef {Object} RulesInputGroupProps
 * @property {string | number} key
 * @property {string} title
 * @property {boolean} checkboxVisible
 * @property {Function} onAddNewGroup
 * @property {boolean} footerVisible
 * @property {boolean} titleVisible
 * @property {string} tooltip
 * @property {(groupKey: string) => void} onRemoveThisGroup
 *
 * @param {RulesInputGroupProps} props
 * @returns
 */
const RulesInputGroup = ({ title, onAddNewGroup, footerVisible, titleVisible, tooltip, index, onRemoveThisGroup }) => {
  const { control, getValues, setValue, reset } = useFormContext()
  const { fields, append } = useFieldArray({
    control,
    name: `rule.children[${index}].children`,
  })
  const tooltipBox = <Tooltip>{tooltip}</Tooltip>

  const handleOnClickButton = () => {
    append({
      conditionSelect: undefined,
    })
  }

  const handleRemoveInputGroup = (inputIndex) => {
    let fieldValues = getValues(`rule.children[${index}].children`)
    fieldValues.splice(inputIndex, 1)
    setValue(`rule.children[${index}].children`, fieldValues)
    const formValues = getValues()

    // Save form values to local storage as default values while editing.
    const editId = getValues("rule.Id")
    // These id values used for checking the form values in the local storage while initializing the form.
    sessionStorage.setItem("formValues", JSON.stringify({ id: editId || "newRule", data: formValues }))

    reset(formValues)
  }

  const multipleConditionHeight = fields.length > 1 ? 86 * fields.length : 86

  const multipleConditionStyle = `
      .multiple-condition,
      .group-condition{
        position: relative;
      }    
      .multiple-condition:after {
        content: "";
        width: 50px;
        border: 1px solid #e2e2e2;
        position: absolute;
        top: 50%;
        left: calc(50% + 3px);
        transform: translate(-50%, -50%);
      }
      .multiple-condition .rs-picker-default .rs-picker-toggle, .group-condition .rs-picker-default .rs-picker-toggle{
        border: 1px solid #59afff;
        border-radius: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        box-shadow: 0px 0px 0px 1px white;
      }
      .multiple-condition .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
      .group-condition .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
        top: 5px;
      }
      .vertical-line-${index}{
        position: absolute;
        top: 43px;
        right: 22px;
        transform: translateX(-50%);
        height: calc(${multipleConditionHeight}px - 86px);
        width: 1px;
        background-color: #e2e2e2;
      }
  
      .condition-card-input-content{
        position: relative;
        box-shadow: 0px 0px 0px 1px white;
      }
      .condition-card-input-content:after {
        content: "";
        width: 25px;
        border: 1px solid #e2e2e2;
        border-radius:1px;
        position: absolute;
        top: 50%;
        left: -24px;
      }
  
      .rs-input-number-btn-group-vertical{
        display: none;
      }
  
      .group-condition:after {
        content: "";
        width: 72px;
        border: 1px solid #e2e2e2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        z-index: 0;
      }
    `

  return (
    <Col xs={24}>
      <style>{multipleConditionStyle}</style>
      <Row>
        {!titleVisible && (
          <Col
            xs={24}
            className="flex justify-center flex-col items-center"
          >
            <span className="group-condition">
              <Controller
                name={`rule.children[${index}].relatedCondition`}
                control={control}
                render={({ field }) => (
                  <SelectPicker
                    name={field.name}
                    accepter={SelectPicker}
                    size="xs"
                    searchable={false}
                    cleanable={false}
                    data={[
                      {
                        key: "and",
                        label: <p className="text-blue-400">ve</p>,
                        value: "and",
                      },
                      {
                        key: "or",
                        label: <p className="text-blue-400">veya</p>,
                        value: "or",
                      },
                    ]}
                    value={field.value || "and"}
                    locale={localization.TR.PickerLocaleType.Picker}
                    onChange={(values) => field.onChange(values)}
                  />
                )}
              />
            </span>
          </Col>
        )}
        <Col xs={24}>
          <Card
            title={index === 0 ? "Koşullar" : ""}
            size="sm"
            footer={
              footerVisible && (
                <Button
                  appearance="ghost"
                  size="xs"
                  className="!border-none flex items-center gap-1"
                  onClick={onAddNewGroup}
                >
                  <Icon
                    size={18}
                    name="plus"
                    className="!bg-blue-600"
                  />
                  Yeni Grup Ekle
                </Button>
              )
            }
            marginSize={"sm"}
          >
            <Grid fluid>
              <Row>
                <Col
                  xs={24}
                  className="flex justify-between items-center"
                >
                  <p className="flex items-center gap-1 font-medium leading-6">
                    {title}
                    {tooltip && (
                      <Whisper
                        placement="bottom"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={tooltipBox}
                      >
                        <span>
                          <Icon
                            name={"info"}
                            size={20}
                            className={"!bg-gray-700"}
                          />
                        </span>
                      </Whisper>
                    )}
                  </p>
                  {!!onRemoveThisGroup && (
                    <div
                      className="flex items-center gap-1 text-xs text-red-500 hover:drop-shadow cursor-pointer"
                      onClick={onRemoveThisGroup}
                    >
                      <Icon
                        size={12}
                        name="trash"
                        className="!bg-red-500 cursor-pointer"
                      />
                      Sil
                    </div>
                  )}
                </Col>
                <Col xs={24}>
                  <Row>
                    {fields.length > 1 && (
                      <Col
                        xs={3}
                        className="flex items-center justify-start multiple-condition"
                        style={{ height: multipleConditionHeight }}
                      >
                        <Controller
                          name={`rule.children[${index}].multipleCondition`}
                          control={control}
                          render={({ field }) => (
                            <SelectPicker
                              name={field.name}
                              size="xs"
                              searchable={false}
                              cleanable={false}
                              data={[
                                {
                                  key: "and",
                                  label: <p className="text-blue-400">ve</p>,
                                  value: "and",
                                },
                                {
                                  key: "or",
                                  label: <p className="text-blue-400">veya</p>,
                                  value: "or",
                                },
                              ]}
                              value={field.value || "and"}
                              locale={localization.TR.PickerLocaleType.Picker}
                              onChange={(values) => field.onChange(values)}
                            />
                          )}
                        />
                      </Col>
                    )}

                    <Col xs={fields.length > 1 ? 21 : 24}>
                      <Row>
                        {fields.map((item, fieldIndex) => (
                          <RulesCardInputs
                            key={item.id}
                            onRemove={fields.length > 1 ? () => handleRemoveInputGroup(fieldIndex) : undefined}
                            id={item.id}
                            treeElementVisible={fields.length > 1}
                            mainIndex={index}
                            index={fieldIndex}
                            isMiddleItem={fieldIndex !== 0 && fieldIndex !== fields.length - 1}
                          />
                        ))}
                        <NewAreaButton
                          text="Alt Koşul Ekle"
                          onClick={handleOnClickButton}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}

export default RuleFormItemsCard
