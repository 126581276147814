export function createEditPageUrl(rowData) {
	let {row, data} = rowData
	const queryParams = new URLSearchParams({
		isCustom: String(row.data.isCustom),
		path: row.data.Path,
		name: row.data.Name,
		pageType: row.data.PageType,
		pageState: row.data.State,
		isMainPage: String(row.data.isMainPage),
	});

	return `editPage/${data.Id}?${queryParams.toString()}`;
}