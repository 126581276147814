import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import Icon from "components/Icon"

import { saveUserSettingsRequested } from "redux/actions/saveUserSettings"
import { getSettingsRequested } from "redux/actions/getSettings"
import { TextHtml } from "../../PageManager/layouts/InnerSideBars/TextBlock/Editor";

const About = ({ setSelectedContent, getSettingsData, setLoading }) => {
  const dispatch = useDispatch()

  const [aboutData, setAboutData] = useState(null)
  const [Fullscreen, setFullscreen] = useState(false)

  const about = getSettingsData?.Result.find((i) => i.PropertyName === "Hakkımızda Text")

  const saveAndPublish = () => {
    dispatch(
      saveUserSettingsRequested({
        properties: [
          {
            PropertyId: about.PropertyId,
            PropertyValue: aboutData || "",
            Status: aboutData ? 1 : 0,
          },
        ],
      }),
    )
    setSelectedContent(false)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      dispatch(getSettingsRequested())
    }, 2000)
  }

  useEffect(() => {
    if (getSettingsData) {
      setAboutData(about.PropertyValue)
    }
  }, [about, getSettingsData])

  return (
    <div className="editor-area">
      {Fullscreen ? (
        <div className="bg-[#344054b3] fixed inset-0 z-[60] flex justify-center items-center">
          <div className="bg-white h-5/6 relative w-5/6 rounded-lg overflow-auto">
            <div className="flex justify-end sticky pr-10 top-8 z-10">
              <Icon
                onClick={() => setFullscreen(!Fullscreen)}
                className={"cursor-pointer"}
                name="minimize-2"
                size="25"
                color="#98A2B3"
              />
            </div>
            <TextHtml
              className={"shrink-1"}
              content={aboutData}
              getContent={(content) => {
                setAboutData(content)
              }
              }/>
            <div className="sticky bottom-0 p-4 left-full inline-flex">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setSelectedContent(false)}
                  className={`btn outline`}
                >
                  Vazgeç
                </button>
                <button
                  onClick={saveAndPublish}
                  className="btn"
                >
                  Kaydet ve Yayınla
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative mt-8">
          {aboutData && (
            <TextHtml
              content={aboutData}
              getContent={(content) => {
                setAboutData(content)
              }}
              containerClass="relative"
            >
              <div className="flex justify-end absolute right-3 top-3 z-10 h-0">
                <Icon
                  onClick={() => setFullscreen(!Fullscreen)}
                  className={"cursor-pointer"}
                  name="maximize-2"
                  size="25"
                  color="#98A2B3"
                />
              </div>
            </TextHtml>
          )}
          <div className="button-group flex flex-row justify-end gap-4 mt-6">
            <button
              onClick={() => setSelectedContent(false)}
              className={`btn outline`}
            >
              Vazgeç
            </button>
            <button
              onClick={saveAndPublish}
              className="btn"
            >
              Kaydet ve Yayınla
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default About
