import Icon from "components/Icon"
import React from "react"

const SocialMediaBox = ({ icon, title, link }) => {
  return (
    <div className="flex gap-3 items-center border border-gray-200 p-4">
      {icon.includes("files") ? (
        <img
          src={icon}
          alt={title}
          width={16}
          height={16}
        />
      ) : (
        <Icon
          name={icon}
          size="16"
          color="#98A2B3"
        />
      )}
      <div>
        <h3 className="text-sm text-gray-800 font-medium">{title}</h3>
        <a
          className="text-xs text-gray-500"
          href={link}
        >
          {link}
        </a>
      </div>
    </div>
  )
}

export default SocialMediaBox
